import React, { useRef, useEffect, useState, useContext } from 'react';
import { AppContext } from '@app/utils';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { randHash } from '@app/utils';

export const Ad = props => {
  const [mounted, setMounted] = useState(false); // Make sure that the Ad slot is always rendered at clientside, and avoid the "Prop `id` did not match" warnings.
  useEffect(() => {
    if (!mounted)
      setMounted(true)
  }, []);
  const { val2 } = useContext(AppContext)
  const [aId, setAId] = val2
    const [advertisementBrands, setAdvertisementBrands] = val2
  const Title = props => <div className={`tw-font-body tw-text-xs tw-leading-loose tw-uppercase tw-text-center ${props.white ? 'tw-text-white' : 'tw-text-third-main'}`}>Advertisement</div>;
  // const Leaderboard = props => <div className={`tw-pt-1 tw-pb-6 tw-text-center ${props.type || ''}`} >{props.children}</div>;
  const Leaderboard = props => <div className={`${props.type || ''}`} >{props.children}</div>;
  const MPU = props => <div className={`tw-pt-1 tw-pb-6 tw-text-center | sm:tw-py-0 ${props.type || ''}`}>{props.children}</div>;
  const googleAd = (props.googleAd || false) && mounted;
  const hash = randHash(32);

  const AdScript = (props) => {
    const router = useRouter()
    const slot = useRef(null)
    const { adId, type, targeting } = props;
    let targetId = props.targetId || ''

    useEffect(() => {
      const googletag = window.googletag || {};
      googletag.cmd = googletag.cmd || [];

      googletag.cmd.push(function () {
        googletag.pubads().collapseEmptyDivs();
        let mapping;
        // console.log(type);
        switch (type) {
          case 'categoryNativeAd':
            slot.current = googletag.defineSlot(adId, ['fluid'], targetId)
              .addService(googletag.pubads())
            break;

          case 'topLeaderboard':
            mapping = googletag.sizeMapping()
              .addSize([1024, 0], ['fluid', [970, 250], [728, 90]])
              .addSize([768, 0], ['fluid', [728, 90],])
              .addSize([0, 0], ['fluid', [300, 50], [320, 100]])
              .build();

            slot.current = googletag.defineSlot(adId, [[970, 250], [728, 90], [320, 100], [300, 50], 'fluid'], targetId)
              .defineSizeMapping(mapping)
              .addService(googletag.pubads())
              .setTargeting('pos', ['top-native', 'top-image'])
              .setTargeting('advertisementbrands', advertisementBrands);
            break;

          case 'middleLeaderboard':
            mapping = googletag.sizeMapping()
              .addSize([1024, 0], ['fluid', [970, 250], [728, 90]])
              .addSize([768, 0], ['fluid', [728, 90]])
              .addSize([0, 0], ['fluid', [300, 250]])
              .build();

            slot.current = googletag.defineSlot(adId, [[970, 250], [728, 90], [300, 250], 'fluid'], targetId)
              .defineSizeMapping(mapping)
              .addService(googletag.pubads())
              .setTargeting('advertisementbrands', advertisementBrands);
            break;

          case 'homeMiddleLeaderboard':
            mapping = googletag.sizeMapping()
              .addSize([1700, 0], ['fluid', [970, 250], [728, 90]])
              .addSize([1124, 0], ['fluid', [728, 90]])
              .addSize([0, 0], ['fluid', [300, 250]])
              .build();

            slot.current = googletag.defineSlot(adId, [[970, 250], [728, 90], [300, 250], 'fluid'], targetId)
              .defineSizeMapping(mapping)
              .addService(googletag.pubads())
              .setTargeting('advertisementbrands', advertisementBrands);
            break;

          case 'bottomLeaderboard':
            mapping = googletag.sizeMapping()
              .addSize([768, 0], ['fluid', [728, 90]])
              .addSize([0, 0], ['fluid', [300, 50], [320, 100]])
              .build();

            slot.current = googletag.defineSlot(adId, [[728, 90], [320, 100], [300, 50], 'fluid'], targetId)
              .defineSizeMapping(mapping)
              .addService(googletag.pubads())
              .setTargeting('advertisementbrands', advertisementBrands);
            break;
          
          case 'search':
            mapping = googletag.sizeMapping()
              .addSize([640, 0], [420, 150])
              .addSize([0, 0], [288, 103])
              .build();
            slot.current = googletag.defineSlot(adId, [[288, 103]], targetId)
              .defineSizeMapping(mapping)
              .addService(googletag.pubads())
              .setTargeting('advertisementbrands', advertisementBrands);
            break;
          case 'prestitial':
            mapping = googletag.sizeMapping()
              .addSize([1280, 0], ['fluid', [800, 600], [640, 480]])
              .addSize([768, 0], ['fluid', [640, 480]])
              .addSize([0, 0], ['fluid', [300, 250]])
              .build();
            slot.current = googletag.defineSlot(adId, [[800, 600], [640, 480], [300, 250], 'fluid'], targetId)
              .defineSizeMapping(mapping)
              .addService(googletag.pubads())
              .setTargeting('advertisementbrands', advertisementBrands);
            break;
          case 'house':
          case 'fourthMpu':
          case 'fourthMpuMobile':
            // case 'leftMpu':
            // case 'leftMpuMobile':  
            mapping = googletag.sizeMapping()
              .addSize([0, 0], ['fluid', [300, 250]])
              .build();
            slot.current = googletag.defineSlot(adId, [[300, 250], 'fluid'], targetId)
              .defineSizeMapping(mapping)
              .addService(googletag.pubads())
              .setTargeting('advertisementbrands', advertisementBrands);
            break;
          case 'firstMpu':
            mapping = googletag.sizeMapping()
              .addSize([0, 0], ['fluid', [300, 600], [300, 250]])
              .build();
            slot.current = googletag.defineSlot(adId, [[300, 600], [300, 250], 'fluid'], targetId)
              .defineSizeMapping(mapping)
              .addService(googletag.pubads())
              .setTargeting('advertisementbrands', advertisementBrands);
              // .setTargeting('pos', ['mpu-image', 'mpu-native']);
            break;
          default:
            mapping = googletag.sizeMapping()
              .addSize([0, 0], ['fluid', [300, 600], [300, 250]])
              .build();
            slot.current = googletag.defineSlot(adId, [[300, 600], [300, 250], 'fluid'], targetId)
              .defineSizeMapping(mapping)
              .addService(googletag.pubads())
              .setTargeting('advertisementbrands', advertisementBrands);
            // .setTargeting('aid',aId);
            break;
        }
        googletag.enableServices();
      });

      googletag.cmd.push(function () {
        googletag.display(targetId);
      });
      //}


    }, [adId, aId, targetId, type, slot, advertisementBrands]);

    switch (type) {
      case 'categoryNativeAd':
        return (
          <div id={`${targetId}`} className={`adSlot ${type}`}>
            {/* {targetId} */}
          </div>
        )
      case 'homeMiddleLeaderboard':
        return (
          <div id={`${targetId}`} className={`adSlot tw-max-h-[400px] before:tw-content-['Advertisement'] before:tw-block before:tw-pt-1 before:tw-font-body before:tw-text-xs before:tw-leading-loose before:tw-uppercase before:tw-text-center ${props.white ? 'before:tw-text-white' : 'before:tw-text-third-main'}  after:tw-content-[''] after:tw-block after:tw-pb-6`}>
            {/* {targetId} */}
          </div>
        )
      default:
        return (
          <div id={`${targetId}`} className={`adSlot${type == 'topLeaderboard' ? ' tw-w-full tw-max-w-screen-3xl tw-mx-auto tw-px-2 sm:tw-px-4' : ''} before:tw-content-['Advertisement'] before:tw-block before:tw-pt-1 before:tw-font-body before:tw-text-xs before:tw-leading-loose before:tw-uppercase before:tw-text-center ${props.white ? 'before:tw-text-white' : 'before:tw-text-third-main'}  after:tw-content-[''] after:tw-block after:tw-pb-6`}>
            {/* {targetId} */}
          </div>
        )
    }
  }

  switch (props.type) {
    case 'categoryNativeAd':
      return googleAd && <AdScript adId={`${process.env.NEXT_PUBLIC_GOOGLE_AD_ID}/category-native-ad`} type={props.type} targetId={`gpt-ad-category-native-ad-${hash}`} />

    case 'topLeaderboard':
      return <Leaderboard type={props.type} >
        {googleAd && <AdScript adId={`${process.env.NEXT_PUBLIC_GOOGLE_AD_ID}/top-leaderboard`} type={props.type} targetId={`gpt-ad-top-leaderboard-${hash}`} />}
      </Leaderboard>

    case 'middleLeaderboard':
      return <Leaderboard type={props.type}>
        {googleAd && <AdScript adId={`${process.env.NEXT_PUBLIC_GOOGLE_AD_ID}/mid-leaderboard`} type={props.type} targetId={`gpt-ad-mid-leaderboard-${hash}`} />}
      </Leaderboard>

    case 'bottomLeaderboard':
      return <Leaderboard type={props.type}>
        {googleAd && <AdScript adId={`${process.env.NEXT_PUBLIC_GOOGLE_AD_ID}/bottom-leaderboard`} type={props.type} targetId={`gpt-ad-bottom-leaderboard-${hash}`} />}
      </Leaderboard>

    case 'firstMpu':
      return <MPU type={props.type}>
        {googleAd && <AdScript adId={`${process.env.NEXT_PUBLIC_GOOGLE_AD_ID}/1st-mpu`} type={props.type} targetId={`gpt-ad-1st-mpu-${hash}`} />}
      </MPU>

    case 'secondMpu':
      return <MPU type={props.type}>
        {googleAd && <AdScript adId={`${process.env.NEXT_PUBLIC_GOOGLE_AD_ID}/2nd-mpu`} type={props.type} targetId={`gpt-ad-2nd-mpu-${hash}`} />}
      </MPU>

    case 'thirdMpu':    
    case 'leftMpu':
      return <MPU type={props.type}>
        {googleAd && <AdScript adId={`${process.env.NEXT_PUBLIC_GOOGLE_AD_ID}/leftmpu`} type={props.type} targetId={`gpt-ad-left-mpu-${hash}`} />}
      </MPU>

    case 'thirdMpuMobile':
    case 'leftMpuMobile':
      return <MPU type={props.type}>
        {googleAd && <AdScript adId={`${process.env.NEXT_PUBLIC_GOOGLE_AD_ID}/leftmpumobile`} type={props.type} targetId={`gpt-ad-left-mpu-mobile-${hash}`} />}
      </MPU>

    // case 'fourthMpu':
    //   return <MPU type={props.type}>
    //     <Title />
    //     {googleAd && <AdScript adId={`${process.env.NEXT_PUBLIC_GOOGLE_AD_ID}/4th-mpu`} type={props.type} targetId={`gpt-ad-4th-mpu-${hash}`} />}}
    //   </MPU>
    
    // case 'fourthMpuMobile':
    //   return <MPU type={props.type}>
    //     <Title />
    //     {googleAd && <AdScript adId={`${process.env.NEXT_PUBLIC_GOOGLE_AD_ID}/4th-mpu-mobile`} type={props.type} targetId={`gpt-ad-4th-mpu-mobile-${hash}`} />}
    //   </MPU>

    case 'homeMiddleLeaderboard':
      return <Leaderboard type={props.type}>
        {googleAd && <AdScript adId={`${process.env.NEXT_PUBLIC_GOOGLE_AD_ID}/mid-leaderboard`} type={props.type} targetId={`gpt-ad-mid-leaderboard-${hash}`} />}
      </Leaderboard>

    case 'search':
      return <div className='tw-text-center'>
        {googleAd && <AdScript adId={`${process.env.NEXT_PUBLIC_GOOGLE_AD_ID}/search`} type={props.type} targetId={`gpt-ad-search-${hash}`} />}
      </div>

    case 'prestitial':
      return <div className='tw-text-center'>
        {googleAd && <AdScript adId={`${process.env.NEXT_PUBLIC_GOOGLE_AD_ID}/prestitial`} type={props.type} targetId={`gpt-ad-prestitial-${hash}`} />}
      </div>

    case 'house':
      return <MPU type={props.type}>
        {googleAd && <AdScript adId={`${process.env.NEXT_PUBLIC_GOOGLE_AD_ID}/house`} type={props.type} targetId={`gpt-ad-house-${hash}`} />}
      </MPU>

    default:
      return null;
  }
}

export default Ad;

Ad.propTypes = {
  /**
  * Type of ad name
  */
  type: PropTypes.string.isRequired,
  /**
  * Google ad get
  */
  googleAd: PropTypes.bool,
}
